import { Dictionary } from "./Dictionary";

export const frDictionary: Dictionary = {
  global: {
    error: "Désolé, une erreur est survenue.",
  },
  page404: {
    description: "La page que vous cherchez n'existe pas.",
  },
  header: {
    title: "Rael CALITRO",
    description: "En savoir plus professionnellement sur moi.",
  },
  dialogs: {
    contact: {
      title: "Contact",
      email: "Email :",
      githubPerso: "GitHub personnel",
      githubPro: "GitHub professionnel",
      cv: "Curriculum vitae",
    },
  },
  home: {
    title: "Accueil",
    myProfile: {
      name: "Rael CALITRO",
      job: "Développeur Web 3.0 Fullstack",
      speciality:
        "Fullstack, IA, Blockchain, Infrastructure, Architecture, TDD, DDD, Clean Code, Enseignement, Français, Anglais",
      descriptions: [
        "Je travaille dans le domaine du développement informatique depuis plus de 6 ans.",
        "J'ai entrepris des études approfondies en développement logiciel pour accroître et certifier mes compétences acquises en autodidacte, obtenant mon diplôme de Mastère Expert en développement logiciel (BAC+5) avec les félicitations du jury.",
        "Au cours de ce cursus au sein de Simplon, Campus ID et Sophia Ynov Campus, j'ai pu me spécialiser dans le développement web, les tests logiciels, l'architecture logicielle, l'intelligence artificielle et la blockchain.",
      ],
      histories: [
        {
          title: "Aujourd'hui, l'indépendance",
          descriptions: [
            "J'ai pu évoluer professionnellement dans plusieurs environnements, de l'entreprise établie à la start-up en passant par l'enseignement.",
            "J'y ai rencontré plusieurs esprits brillants, pratiqué sur beaucoup de technologies, selon de nombreuses méthodes travail et sous différentes cadences",
            "J'ai pu m'imprégner, analyser et différencier les différentes pratiques avec un regard critique me permettant de mieux en comprendre les avantages et inconvenients et ainsi cerner les enjeux professionnels.",
            "C'est fort de mes expériences que j'entreprends la suite de ma carrière de façon indépendantes, avec toujours plus d'appétit technologique et de challenges à relever dans mes domaines de prédilection: Le Web en full-stack ajoutant le Web 3.0 et l'Intelligence Articielle, ayant également toujours à coeur d'enseigner.",
          ],
        },
        {
          title: "En parallèle, Ynov Campus, enseignement en classe de Mastère",
          descriptions: [
            "Depuis toujours, je suis animé par la transmission de savoirs et de compétences 👨‍🏫. C'est pourquoi, je donne aujourd'hui des cours à Ynov Campus de Sophia-Antipolis, école supérieure, en classe de Mastère informatique sur les sujets du développement web et tests logiciels.",
          ],
        },
        {
          title: "Mon ascension sur le Web 3.0 et l'IA, Smart-Chain",
          descriptions: [
            "J'ai travaillé pendant presque 2 ans chez Smart-Chain, startup à Paris dans l'univers Blockchain et de l'intelligence artificielle en développant plusieurs produits dont un pour le Commissariat à l’Energie Atomique et aux énergies alternatives (CEA) parmi d'autres tout aussi prestigieux. ✨",
            "J'y ai été développeur fullstack sur une infra Cloud, utilisant principalement NodeJS, Typescript, React, Next, Prisma, PostgresSQL, websocket, queueing, pour construire des applications web interagissant avec :",
            "- Des blockchains, appels smart contracts, indexage, IPFS 🔐",
            "- Des modèles d'IA que j'ai développé en interne dans le domaine de Computer Vision (CNN, GAN, ViTransformers) et le domaine des données tabulaires (RNN, LSTM) pour de la classification, détection, segmentation, régression 📶 🎦",
            "- Des modèles d'IA LLM comme ceux de OpenAI et plus récemment MistralAI, assistants, prompting, fine-tuning, RAG 🆒 💬",
            "Les méthodes de travail adoptées dépendent des produits à développer Kanban ou Scrum en général.",
          ],
        },
        {
          title:
            "L'aventure Ricardo : De frontend à backend dans une entreprise anglophone",
          descriptions: [
            "J'ai travaillé sur la 1ère marketplace Suisse. 🛍️",
            "Un an développeur frontend avec React hooks et Typescript sur base d'hydratation. Utilisation de librairies comme Material-UI, React-Query ou encore Redux.",
            "Puis un an développeur backend avec JAVA Spring, .Net, Kafka, PostgresSQL et SQLServer, le tout autour d'une infra Kubernetes gérant nos micro-services. Nous travaillions selon la méthode Agile Scrum.",
            "J'y ai également amélioré mon anglais au travers des interactions quotidiennes avec mes collègues anglophones et diverses présentation.",
          ],
        },
        {
          title: "Mes débuts avec le Machine Learning chez Infotel",
          descriptions: [
            "Pendant 1 an, j'ai conçu un logiciel desktop en JAVA avec une composante de Machine Learning 🤖, le logiciel servant à retrouver les métadonnées de documents brevetés de l'Office Européen des Brevets grâce à la méthode CRF du Machine Learning (Conditional random fields).",
            "J'y ai également aiguisé des compétences en architecture logicielle à travers la programmation orienté objet et de nombreuses sessions de pair-coding avec mon mentor.",
          ],
        },
        {
          title: "Mon temps libre ? Entre bon temps et veille technologique",
          descriptions: [
            "Je suis plutôt bon vivant, j'aime ma famille, les sorties entre amis, les voyages, la musique, le sport, la lecture, les jeux vidéos, les séries et les films comme beaucoup de personnes 🥳.",
            "Mais mes vieux démon me rattrapent très régulièrement, en effet je suis aussi passionné par la veille technologique, la recherche et le développement de projets personnels 🧪.",
            "Et c'est à ce titre que vous trouverez sur ce site des projets et articles, je vous ai épargné les brouillons 😅 que je ne compte plus (mon github en témoigne). En effet, j'adore explorer, optimiser, créer, partager et apprendre 🤓.",
            "Je suis sensible à l'architecture logicielle et l'art de construire durablement, avec élégance et efficacité les solutions de demain 🏗️.",
            "Résilience, flexibilité, maintenabilité, sécurité et optimisation, contrebalancés avec les compromis de production, de temps et d'argent, ainsi que la compréhension de leurs enjeux, guident mon quotidien et ma conscience professionnels.",
            "J'affectionne tout particulièrement l'intelligence artificielle et tous les travaux qui gravitent autour.",
          ],
        },
        {
          title: "Ma philosophie",
          descriptions: [
            "Apprenez, expérimentez, contribuez, aidez et ressentez ; que vous échouiez ou réussissiez, cela servira un jour ou l'autre, tant techniquement qu'humainement.",
          ],
        },
      ],
    },
  },
  projects: {
    title: "Projets",
    descriptions: [
      "Voici quelques projets que j'ai réalisés, ils sont en ligne accessibles en HTTPS à travers Cloudflare.",
      "Pour celà je dispose d'un VPS (virtual private server) sous Linux et d'un nom de domaine.",
      "J'ai configuré l'intégration et déploiement continu (CI/CD) de ces projets sur Azure Devops branché à mon Github.",
      "Les applications backend sont exécutées dans des conteneurs Docker.",
      "J'ai ainsi pu exercer mes compétences en administration système, en sécurité, en développement, en déploiement et en intégration continue.",
    ],
    sections: [
      {
        title: "Web3-blog",
        descriptions: [
          "Une application décentralisée (dApp) personnelle permettant aux utilisateurs de créer et de lire des articles de blog, avec des métadonnées stockées sur IPFS et des CID ancrés sur la blockchain du testnet Sepolia.",
          "Le Web3-blog est un projet personnel conçu pour explorer la création et le stockage de contenu décentralisé.",
          "Les utilisateurs peuvent créer et lire des articles de blog, avec des métadonnées stockées sur le Système de Fichiers InterPlanétaire (IPFS) et des Identifiants de Contenu (CID) ancrés sur la blockchain du testnet Sepolia.",
          "Cette approche garantit à la fois la décentralisation et l'immutabilité du contenu.",
        ],
        date: "2025",
        links: [
          {
            label: "Website",
            href: "https://web3-blog.rael-calitro.ovh",
          },
          {
            label: "Github",
            href: "https://github.com/rael06/web3-blog",
          },
        ],
        href: "https://web3-blog.rael-calitro.ovh",
        tags: [
          "TypeScript",
          "Next.js v15",
          "React",
          "Material-UI",
          "Solidity",
          "Hardhat",
          "IPFS",
          "Kubo",
          "Docker",
          "VPS",
          "MetaMask",
          "Sepolia Testnet",
        ],
      },
      {
        title: "CEA-Moonshot",
        descriptions: [
          "Développement d'un tableau de bord Web 3.0 SaaS pour le CEA, le Commissariat à l'énergie atomique et aux énergies alternatives.",
          "Il permet de surveiller les émission de gaz à effet de serre (comme le CO2) sur un tableau de bord personnalisable.",
          "Les données proviennent de plusieurs laboratoires et sont ancrées sur la Blockchain.",
          "Ce SaaS propose une fonctionnalité d'audition des données, ce qui était un défi en raison de multiples architectures de données et de processus dynamiques fournis par chaque laboratoire. De plus, le SaaS propose une auditabilité locale des données, et pour traiter les deux méthodes d'audit, j'ai mis en place une architecture en micro-services impliquant la gestion de la conteneurisation avec Docker.",
          "Une audition de données consiste à interagir avec des smart contracts pour vérifier que ces données sont correctement ancrées et fiables.",
          "Vous trouverez une démo utilisant des données fictives.",
        ],
        date: "2024",
        links: [
          {
            label: "Site Web",
            href: "https://cea-moonshot.rael-calitro.ovh",
          },
        ],
        href: "https://cea-moonshot.rael-calitro.ovh",
        tags: [
          "Node",
          "Strapi",
          "Fastify",
          "React",
          "Typescript",
          "Docker",
          "MaterialUI",
          "Clean architecture DDD",
        ],
      },
      {
        title: "Quizzer-llm",
        descriptions: [
          "Ce projet porte sur un Quiz dont les questions et réponses sont gérées par un modèle IA LLM : GPT 4o mini.",
          "A travers ce POC, je partage une façon de consommer un modèle d'IA à travers une API REST, et comment on peut interagir avec un frontend.",
        ],
        date: "2024",
        links: [
          {
            label: "Site web",
            href: "https://quizzer-llm-apimodel.rael-calitro.ovh",
          },
          {
            label: "Github",
            href: "https://github.com/rael06/quizzer-llm-apimodel",
          },
        ],
        href: "https://quizzer-llm-apimodel.rael-calitro.ovh",
        tags: [
          "IA",
          "LLM",
          "Web",
          "Node",
          "Fastify",
          "Typescript",
          "React",
          "Prompting",
        ],
      },
      {
        title: "Ylunch",
        descriptions: [
          "Un projet réalisé durant mes études et présenté au jury d'examen.",
          "L'application propose la réservation de repas par les étudiants auprès de restaurants autours de l'école.",
          "Ce projet n'est pas en production, mais il est déployé pour démonstration. Cela m'a permis de couvrir un large panel de compétence : gestion de projet, gestion client, maquettage, conception, réalisation, communication et présentation d'un projet complet.",
          "Sur la partie technique, j'ai mis en place tout mes savoirs du moment : architecture logicielle DDD, tests unitaires, développement, déploiement et intégration continue (CI/CD), et sécurité.",
          "Il s'agit d'une application web avec une API REST en backend et un frontend en React. Le backend est en Dotnet avec une base de données SQL Server. Les tests unitaires sont présents et le code est organisé selon le modèle DDD",
        ],
        href: "https://ylunch.rael-calitro.ovh",
        date: "2022",
        links: [
          {
            label: "Site web",
            href: "https://ylunch.rael-calitro.ovh",
          },
          {
            label: "Github frontend",
            href: "https://github.com/rael06/YLunchUI",
          },
          {
            label: "Github backend",
            href: "https://github.com/rael06/YLunchAPI",
          },
        ],
        tags: [
          "Web",
          "DDD",
          "Tests unitaires",
          "CI/CD",
          "SonarCloud",
          "Dotnet C#",
          "SQL",
          "Typescript",
          "React",
        ],
      },
      {
        title: "Geo locator",
        descriptions: [
          "Ce petit projet est une simple application utilisant une carte de France.",
          "Il s'agit d'un localisateur de position",
        ],
        href: "https://geoapp.rael-calitro.ovh/addresses",
        date: "2021",
        links: [
          {
            label: "Site web",
            href: "https://geoapp.rael-calitro.ovh/addresses",
          },
        ],
        tags: ["Web", "Typescript", "React"],
      },
      {
        title: "QR encoder",
        descriptions: [
          "Ce projet propose un simple générateur de QR code.",
          "Il permet de générer des QR codes à partir de texte.",
        ],
        href: "https://qr-code-generator.rael-calitro.ovh",
        date: "2021",
        links: [
          {
            label: "Site web",
            href: "https://qr-code-generator.rael-calitro.ovh",
          },
        ],
        tags: ["Web", "Typescript", "React"],
      },
      {
        title: "AI Calculator",
        descriptions: [
          "Ce projet livre une calculatrice basique dans laquelle il faut dessiner les chiffres, lesquels sont interprétés par un réseau de neurones Deep Learning CNN pour être calculés.",
          "Ce modèle d'IA de détection de chiffres manuscrits à été configuré et entraîné sur un datasets par mes soins, qui est exposé à travers une API REST, consommé par un frontend et qui sert à calculer les chiffres dessinés.",
          "En survolant les chiffres, on peut voir les statistiques de prédiction du modèle.",
        ],
        href: "https://number-detection.rael-calitro.ovh/",
        date: "2020",
        links: [
          {
            label: "Site web",
            href: "https://number-detection.rael-calitro.ovh/",
          },
        ],
        tags: [
          "IA",
          "CNN",
          "Model training",
          "Model serving",
          "Keras",
          "Tensorflow",
          "Classification",
          "Web",
          "Angular",
          "Typescript",
          "Python",
          "Django",
        ],
      },
    ],
  },
  articles: {
    title: "Articles",
    descriptions: [
      "Voici quelques articles que j'ai rédigé. Hébergés sur mon Notion ou Github.",
      "Ils traitent de sujets variés autour du développement web et de l'intelligence artificielle.",
    ],
    sections: [
      {
        title: "AI concepts",
        descriptions: [
          "Une série d'articles en français sur des concepts de l'intelligence artificielle avec un focus sur le Deep Learning et les réseaux de neurones, différentes architectures, techniques et stratégies 🧠.",
          "Je traite plus spécifiquement d'un panorama sur l'IA, de RNN, CNN, Transformers, embeddings et vecteurs, LLM, Computer vision, Fine-tuning, RAG, Prompt engineering.",
        ],
        href: "https://rael-calitro.notion.site/Voyage-au-coeur-de-l-intelligence-artificielle-6474c2f4843e4e07a756d7d406f32004?pvs=4",
        date: "2024",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Voyage-au-coeur-de-l-intelligence-artificielle-6474c2f4843e4e07a756d7d406f32004?pvs=4",
          },
        ],
        tags: [
          "AI",
          "Deep Learning",
          "AI architecture",
          "AI creation",
          "AI strategy",
          "Embeddings & vectors",
          "Prompt-engineering",
          "Rag",
          "Fine-tuning",
          "Notebook",
        ],
      },
      {
        title: "Classification d'images par IA avec RAG",
        descriptions: [
          "L'un de mes notebooks, présentant un exemple d'utilisation de RAG pour classer des images avec Python, Pytorch et PGVector.",
          "Dans cet exemple, j'ai adopté une approche basée sur les vecteurs et les embeddings pour classer les images en fonction de leurs voisins KNN.",
        ],
        href: "https://gist.github.com/rael06/76069b911018ee9e8491f1a7c7fb9fe4",
        date: "2024",
        links: [
          {
            label: "GitHub gist",
            href: "https://gist.github.com/rael06/76069b911018ee9e8491f1a7c7fb9fe4",
          },
        ],
        tags: [
          "IA",
          "Deep Learning",
          "Embeddings & Vecteurs",
          "RAG",
          "Notebook",
        ],
      },
      {
        title: "AI Generic CNN classifier",
        descriptions: [
          "Un de mes notebooks, en anglais, présentant un exemple d'utilisation des réseaux neuronaux convolutionnels (CNN) pour classer des images en utilisant les librairies python Keras et Tensorflow.",
          "Dans cet exemple, j'ai adopté une approche générique où l'utilisateur peut choisir les classes et les images à classer en fonction de l'aborescence des fichiers et dossier du dataset.",
        ],
        href: "https://gist.github.com/rael06/0554f9e19ada5e1507b59e36d417352a",
        date: "2023",
        links: [
          {
            label: "Github gist",
            href: "https://gist.github.com/rael06/0554f9e19ada5e1507b59e36d417352a",
          },
        ],
        tags: [
          "IA",
          "Python",
          "Keras",
          "Tensorflow",
          "CNN",
          "Deep Learning",
          "Classification",
          "Image",
          "Notebook",
        ],
      },
      {
        title: "Concepts Web",
        descriptions: [
          "Mon article en français sur les concepts de bases du web",
        ],
        href: "https://rael-calitro.notion.site/Concepts-05a37b41a6c643d597fc245a9ae7c91b?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Concepts-05a37b41a6c643d597fc245a9ae7c91b?pvs=4",
          },
        ],
        tags: ["Web", "Concepts"],
      },
      {
        title: "Infrastructure Web",
        descriptions: [
          "Mon article en français sur les coulisses du Web : l'infrastructure Web",
        ],
        href: "https://rael-calitro.notion.site/Infrastructure-web-a1ef9b012a6e4afc865647ccda8c6f3a?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Infrastructure-web-a1ef9b012a6e4afc865647ccda8c6f3a?pvs=4",
          },
        ],
        tags: ["Web", "Infrastructure"],
      },
      {
        title: "HTML",
        descriptions: [
          "Mon article en français sur le langage de balisage permettant de structurer un site Web : HTML",
        ],
        href: "https://rael-calitro.notion.site/HTML-8903326a8d874b88ab57528aaba02265?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/HTML-8903326a8d874b88ab57528aaba02265?pvs=4",
          },
        ],
        tags: ["Web", "HTML"],
      },
      {
        title: "CSS",
        descriptions: [
          "Mon article en français sur le style des pages HTML avec CSS",
        ],
        href: "https://rael-calitro.notion.site/CSS-59b9039d309a43e695b451e9a6b529c0?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/CSS-59b9039d309a43e695b451e9a6b529c0?pvs=4",
          },
        ],
        tags: ["Web", "CSS"],
      },
      {
        title: "Javascript",
        descriptions: [
          "Mon article en français sur les fondamentaux de Javascript",
        ],
        href: "https://rael-calitro.notion.site/Javascript-199a13cbe06041f09a4e814a8d3cb65a?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Javascript-199a13cbe06041f09a4e814a8d3cb65a?pvs=4",
          },
        ],
        tags: ["Web", "Javascript"],
      },
      {
        title: "Typescript",
        descriptions: [
          "Mon article en français sur pour bien débuter en Typescript",
        ],
        href: "https://rael-calitro.notion.site/Typescript-29808d1f4b6f4d7e925d143c4cac0ba2?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Typescript-29808d1f4b6f4d7e925d143c4cac0ba2?pvs=4",
          },
        ],
        tags: ["Web", "Typescript"],
      },
    ],
  },
  footer: {
    author: "2021, par Rael CALITRO : ",
    license: "License : ",
  },
};
