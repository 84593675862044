import { Dictionary } from "./Dictionary";

export const enDictionary: Dictionary = {
  global: {
    error: "Sorry, an error occurred.",
  },
  page404: {
    description: "Page not found.",
  },
  header: {
    title: "Rael CALITRO",
    description: "Learn more about me professionally.",
  },
  dialogs: {
    contact: {
      title: "Contact",
      email: "Email:",
      githubPerso: "Personal GitHub",
      githubPro: "Professional GitHub",
      cv: "Curriculum vitae",
    },
  },
  home: {
    title: "Home",
    myProfile: {
      name: "Rael CALITRO",
      job: "Web 3.0 Fullstack Developer",
      speciality:
        "Fullstack, AI, Blockchain, Infrastructure, Architecture, TDD, DDD, Clean Code, Teaching, French, English",
      descriptions: [
        "I have been working in the field of software development for over 6 years.",
        "I undertook advanced studies in software development to enhance and certify the skills I acquired as a self-taught developer, obtaining my Master's degree in Software Development with honors.",
        "During this course at Simplon, Campus ID, and Sophia Ynov Campus, I specialized in web development, software testing, software architecture, artificial intelligence, and blockchain.",
      ],
      histories: [
        {
          title: "Today, Independence",
          descriptions: [
            "I have been able to grow professionally in various environments, from established companies to startups, as well as in education.",
            "I’ve met many brilliant minds, worked with numerous technologies, adapted to various work methods, and operated at different paces.",
            "I’ve been able to absorb, analyze, and differentiate between the various practices with a critical eye, allowing me to better understand their advantages and drawbacks, and thus grasp the professional challenges.",
            "It is with the strength of these experiences that I now embark on the next stage of my career independently, with an ever-growing appetite for technology and challenges to tackle in my favorite fields: Full-stack Web, with the addition of Web 3.0 and Artificial Intelligence, while always maintaining my passion for teaching.",
          ],
        },
        {
          title: "In Parallel, Ynov Campus, Teaching in Master’s Classes",
          descriptions: [
            "Teaching and sharing knowledge have always been a passion of mine 👨‍🏫. This is why I now teach at Ynov Campus in Sophia-Antipolis, a higher education institution, in the Master’s program on topics such as web development and software testing.",
          ],
        },
        {
          title: "My Breakthrough in Web 3.0 and AI, Smart-Chain",
          descriptions: [
            "I worked for nearly two years at Smart-Chain, a startup in Paris in the Blockchain and Artificial Intelligence space, where I developed several products, including one for the French Alternative Energies and Atomic Energy Commission (CEA), among other prestigious projects. ✨",
            "I was a fullstack developer on a cloud infrastructure, mainly using NodeJS, Typescript, React, Next, Prisma, PostgreSQL, WebSocket, and queuing, to build web applications interacting with:",
            "- Blockchains, smart contract calls, indexing, IPFS 🔐",
            "- AI models I developed in-house in the field of Computer Vision (CNN, GAN, ViTransformers) and tabular data (RNN, LSTM) for classification, detection, segmentation, and regression 📶 🎦",
            "- LLM AI models such as those from OpenAI and more recently MistralAI, for assistants, prompting, fine-tuning, and RAG 🆒 💬",
            "The working methods used depend on the products being developed, typically Kanban or Scrum.",
          ],
        },
        {
          title:
            "The Ricardo adventure: From frontend to backend in an English-speaking company",
          descriptions: [
            "I worked on the first marketplace in Switzerland. 🛍️",
            "One year as a frontend developer with React hooks and Typescript based on hydration. Using libraries like Material-UI, React-Query, and Redux.",
            "Then one year as a backend developer with JAVA Spring, .Net, Kafka, PostgresSQL, and SQLServer, all on a Kubernetes infrastructure managing our microservices. We worked according to the Agile Scrum methodology.",
            "I also improved my English through daily interactions with my English-speaking colleagues and various presentations.",
          ],
        },
        {
          title: "My beginnings with Machine Learning at Infotel",
          descriptions: [
            "For one year, I designed desktop software in JAVA with a Machine Learning component 🤖. The software was used to retrieve metadata from patent documents of the European Patent Office using the CRF (Conditional Random Fields) method of Machine Learning.",
            "I also honed my skills in software architecture through object-oriented programming and many pair-coding sessions with my mentor.",
          ],
        },
        {
          title: "My free time? Between good times and technology watch",
          descriptions: [
            "I am quite a bon vivant, I love my family, outings with friends, traveling, music, sports, reading, video games, series, and movies like many people 🥳.",
            "But my old habits often catch up with me, as I am also passionate about technology watch, research, and developing personal projects 🧪.",
            "And for this reason, you will find projects and articles on this site, sparing you the drafts 😅 that I can't even count (my GitHub is a testament to this). Indeed, I love to explore, optimize, create, share, and learn 🤓.",
            "I am sensitive to software architecture and the art of building sustainable, elegant, and efficient solutions for the future 🏗️.",
            "Resilience, flexibility, maintainability, security, and optimization, balanced with the compromises of production, time, and money, as well as understanding their stakes, guide my professional daily life and conscience.",
            "I have a particular fondness for artificial intelligence and all the work that revolves around it.",
          ],
        },
        {
          title: "My philosophy",
          descriptions: [
            "Learn, experiment, contribute, help, and feel; whether you fail or succeed, it will serve one day or another, both technically and humanly.",
          ],
        },
      ],
    },
  },
  projects: {
    title: "Projects",
    descriptions: [
      "Here are some projects I have carried out, they are online accessible in HTTPS through Cloudflare.",
      "For this, I have a VPS (virtual private server) under Linux and a domain name.",
      "I have configured continuous integration and deployment (CI/CD) of these projects on Azure Devops linked to my Github.",
      "The backend applications run in Docker containers.",
      "This has allowed me to exercise my skills in system administration, security, development, deployment, and continuous integration.",
    ],
    sections: [
      {
        title: "Web3-blog",
        descriptions: [
          "A personal decentralized application (dApp) enabling users to create and read blog posts, with metadata stored on IPFS and CIDs anchored on the Sepolia testnet blockchain.",
          "The Web3 Blog is a personal project designed to explore decentralized content creation and storage.",
          "Users can create and read blog posts, with metadata stored on the InterPlanetary File System (IPFS) and Content Identifiers (CIDs) anchored on the Sepolia testnet blockchain.",
          "This approach ensures both decentralization and immutability of content.",
        ],
        date: "2025",
        links: [
          {
            label: "Website",
            href: "https://web3-blog.rael-calitro.ovh",
          },
          {
            label: "Github",
            href: "https://github.com/rael06/web3-blog",
          },
        ],
        href: "https://web3-blog.rael-calitro.ovh",
        tags: [
          "TypeScript",
          "Next.js v15",
          "React",
          "Material-UI",
          "Solidity",
          "Hardhat",
          "IPFS",
          "Kubo",
          "Docker",
          "VPS",
          "MetaMask",
          "Sepolia Testnet",
        ],
      },
      {
        title: "CEA-Moonshot",
        descriptions: [
          "Development of a Web 3.0 SaaS dashboard for the CEA, the French Alternative Energies and Atomic Energy Commission.",
          "It allows monitoring greenhouse gas emissions (like CO2) on a customizable dashboard.",
          "The data comes from multiple laboratories and is anchored on the Blockchain.",
          "This SaaS provides a data auditing feature, which was challenging due to multiple data architectures and dynamic processes provided by each laboratory. Additionally, the SaaS offers local data auditability. To handle both audit methods, I implemented a microservice architecture involving Docker containerization management.",
          "A data audit involves interacting with smart contracts to verify that the data is properly anchored and trustworthy.",
          "You will find a demo using fake data.",
        ],
        date: "2024",
        links: [
          {
            label: "Website",
            href: "https://cea-moonshot.rael-calitro.ovh",
          },
        ],
        href: "https://cea-moonshot.rael-calitro.ovh",
        tags: [
          "Node",
          "Strapi",
          "Fastify",
          "React",
          "Typescript",
          "Docker",
          "MaterialUI",
          "Clean architecture DDD",
        ],
      },
      {
        title: "Quizzer-llm",
        descriptions: [
          "This project is about a Quiz where the questions and answers are managed by an AI LLM model: GPT 4o mini.",
          "Through this POC, I share a way to use an AI model through a REST API, and how we can interact with a frontend.",
        ],
        date: "2024",
        links: [
          {
            label: "Website",
            href: "https://quizzer-llm-apimodel.rael-calitro.ovh",
          },
          {
            label: "Github",
            href: "https://github.com/rael06/quizzer-llm-apimodel",
          },
        ],
        href: "https://quizzer-llm-apimodel.rael-calitro.ovh",
        tags: [
          "AI",
          "LLM",
          "Web",
          "Node",
          "Fastify",
          "Typescript",
          "React",
          "Prompting",
        ],
      },
      {
        title: "Ylunch",
        descriptions: [
          "A project carried out during my studies and presented to the examination jury.",
          "The application offers meal reservations by students at restaurants around the school.",
          "This project is not in production, but it is deployed for demonstration. This allowed me to cover a wide range of skills: project management, client management, mock-ups, design, realization, communication, and presentation of a complete project.",
          "On the technical side, I applied all my knowledge of the time: software architecture DDD, unit testing, development, deployment, and continuous integration (CI/CD), and security.",
          "It is a web application with a REST API backend and a React frontend. The backend is in Dotnet with a SQL Server database. Unit tests are present, and the code is organized according to the DDD model",
        ],
        href: "https://ylunch.rael-calitro.ovh",
        date: "2022",
        links: [
          {
            label: "Website",
            href: "https://ylunch.rael-calitro.ovh",
          },
          {
            label: "Github frontend",
            href: "https://github.com/rael06/YLunchUI",
          },
          {
            label: "Github backend",
            href: "https://github.com/rael06/YLunchAPI",
          },
        ],
        tags: [
          "Web",
          "DDD",
          "Unit tests",
          "CI/CD",
          "SonarCloud",
          "Dotnet C#",
          "SQL",
          "Typescript",
          "React",
        ],
      },
      {
        title: "Geo locator",
        descriptions: [
          "This small project is a simple application using a map of France.",
          "It is a position locator.",
        ],
        href: "https://geoapp.rael-calitro.ovh/addresses",
        date: "2021",
        links: [
          {
            label: "Website",
            href: "https://geoapp.rael-calitro.ovh/addresses",
          },
        ],
        tags: ["Web", "Typescript", "React"],
      },
      {
        title: "QR encoder",
        descriptions: [
          "This project offers a simple QR code generator.",
          "It allows generating QR codes from text.",
        ],
        href: "https://qr-code-generator.rael-calitro.ovh",
        date: "2021",
        links: [
          {
            label: "Website",
            href: "https://qr-code-generator.rael-calitro.ovh",
          },
        ],
        tags: ["Web", "Typescript", "React"],
      },
      {
        title: "AI Calculator",
        descriptions: [
          "This project delivers a basic calculator in which you have to draw the numbers, which are interpreted by a Deep Learning CNN neural network to be calculated.",
          "This handwritten digit detection AI model was configured and trained by me on a dataset, which is exposed through a REST API, consumed by a frontend, and used to calculate the drawn numbers.",
          "Hovering over the numbers, you can see the model's prediction statistics.",
        ],
        href: "https://number-detection.rael-calitro.ovh/",
        date: "2020",
        links: [
          {
            label: "Website",
            href: "https://number-detection.rael-calitro.ovh/",
          },
        ],
        tags: [
          "AI",
          "CNN",
          "Model training",
          "Model serving",
          "Keras",
          "Tensorflow",
          "Classification",
          "Web",
          "Angular",
          "Typescript",
          "Python",
          "Django",
        ],
      },
    ],
  },
  articles: {
    title: "Articles",
    descriptions: [
      "Here are some articles I have written. Hosted on my Notion or Github.",
      "They cover various subjects around web development and artificial intelligence.",
    ],
    sections: [
      {
        title: "AI concepts",
        descriptions: [
          "A series of articles in French about concepts in artificial intelligence with a focus on Deep Learning and neural networks, different architectures, techniques, and strategies 🧠.",
          "I specifically address AI overview, RNN, CNN, Transformers, embeddings and vectors, LLM, Computer vision, Fine-tuning, RAG, Prompt engineering.",
        ],
        href: "https://rael-calitro.notion.site/Voyage-au-coeur-de-l-intelligence-artificielle-6474c2f4843e4e07a756d7d406f32004?pvs=4",
        date: "2024",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Voyage-au-coeur-de-l-intelligence-artificielle-6474c2f4843e4e07a756d7d406f32004?pvs=4",
          },
        ],
        tags: [
          "AI",
          "Deep Learning",
          "AI architecture",
          "AI creation",
          "AI strategy",
          "Embeddings & vectors",
          "Prompt-engineering",
          "Rag",
          "Fine-tuning",
          "Notebook",
        ],
      },
      {
        title: "AI image classification using RAG",
        descriptions: [
          "One of my notebooks, presenting an example of using RAG to classify images using Python with Pytorch and PGVector.",
          "In this example, I adopted a vector & embeddings approach to classify images based on their KNN neighbors.",
        ],
        href: "https://gist.github.com/rael06/76069b911018ee9e8491f1a7c7fb9fe4",
        date: "2024",
        links: [
          {
            label: "Github gist",
            href: "https://gist.github.com/rael06/76069b911018ee9e8491f1a7c7fb9fe4",
          },
        ],
        tags: [
          "AI",
          "Deep Learning",
          "Embeddings & vectors",
          "Rag",
          "Notebook",
        ],
      },
      {
        title: "AI Generic CNN classifier",
        descriptions: [
          "One of my notebooks, in English, presenting an example of using convolutional neural networks (CNN) to classify images using the Python libraries Keras and Tensorflow.",
          "In this example, I adopted a generic approach where the user can choose the classes and images to classify based on the dataset's file and folder hierarchy.",
        ],
        href: "https://gist.github.com/rael06/0554f9e19ada5e1507b59e36d417352a",
        date: "2023",
        links: [
          {
            label: "Github gist",
            href: "https://gist.github.com/rael06/0554f9e19ada5e1507b59e36d417352a",
          },
        ],
        tags: [
          "AI",
          "Python",
          "Keras",
          "Tensorflow",
          "CNN",
          "Deep Learning",
          "Classification",
          "Image",
          "Notebook",
        ],
      },
      {
        title: "Web Concepts",
        descriptions: [
          "My article in French on the basic concepts of the web.",
        ],
        href: "https://rael-calitro.notion.site/Concepts-05a37b41a6c643d597fc245a9ae7c91b?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Concepts-05a37b41a6c643d597fc245a9ae7c91b?pvs=4",
          },
        ],
        tags: ["Web", "Concepts"],
      },
      {
        title: "Web Infrastructure",
        descriptions: [
          "My article in French on the behind-the-scenes of the Web: Web infrastructure.",
        ],
        href: "https://rael-calitro.notion.site/Infrastructure-web-a1ef9b012a6e4afc865647ccda8c6f3a?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Infrastructure-web-a1ef9b012a6e4afc865647ccda8c6f3a?pvs=4",
          },
        ],
        tags: ["Web", "Infrastructure"],
      },
      {
        title: "HTML",
        descriptions: [
          "My article in French on the markup language for structuring a Web site: HTML.",
        ],
        href: "https://rael-calitro.notion.site/HTML-8903326a8d874b88ab57528aaba02265?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/HTML-8903326a8d874b88ab57528aaba02265?pvs=4",
          },
        ],
        tags: ["Web", "HTML"],
      },
      {
        title: "CSS",
        descriptions: ["My article in French on styling HTML pages with CSS."],
        href: "https://rael-calitro.notion.site/CSS-59b9039d309a43e695b451e9a6b529c0?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/CSS-59b9039d309a43e695b451e9a6b529c0?pvs=4",
          },
        ],
        tags: ["Web", "CSS"],
      },
      {
        title: "Javascript",
        descriptions: [
          "My article in French on the fundamentals of Javascript.",
        ],
        href: "https://rael-calitro.notion.site/Javascript-199a13cbe06041f09a4e814a8d3cb65a?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Javascript-199a13cbe06041f09a4e814a8d3cb65a?pvs=4",
          },
        ],
        tags: ["Web", "Javascript"],
      },
      {
        title: "Typescript",
        descriptions: [
          "My article in French on getting started with Typescript.",
        ],
        href: "https://rael-calitro.notion.site/Typescript-29808d1f4b6f4d7e925d143c4cac0ba2?pvs=4",
        date: "2023",
        links: [
          {
            label: "Notion",
            href: "https://rael-calitro.notion.site/Typescript-29808d1f4b6f4d7e925d143c4cac0ba2?pvs=4",
          },
        ],
        tags: ["Web", "Typescript"],
      },
    ],
  },
  footer: {
    author: "2021, by Rael CALITRO: ",
    license: "License: ",
  },
};
