import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { memo } from "react";
import PageTemplate from "./components/PageTemplate";
import HomePage from "./components/HomePage";
import Page404 from "./components/Page404";
import { LangContextProvider } from "./common/contexts/lang/context";
import SectionsPage from "./components/SectionsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LangContextProvider>
        <PageTemplate />
      </LangContextProvider>
    ),

    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "projects",
        element: <SectionsPage pageType="projects" />,
      },
      {
        path: "articles",
        element: <SectionsPage pageType="articles" />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default memo(Router);
